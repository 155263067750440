 <template>
  <div>
    <div>
      <navbar />
    </div>
    <div>
      <div v-if="this.response == 'success'" class="alert alert-success" role="alert">
        Update Succesfully
      </div>
      <div v-if="this.response == 'error'" class="alert alert-danger" role="alert">
        Error Occured
      </div>
    </div>
      <ul class="nav nav-tabs center">
        <li class="nav-item">
          <a class="nav-link active" id="editUser" @click="changePage('editUser')">Edit User</a>
        </li>
         <li class="nav-item">
          <a class="nav-link" id="designPage" @click="changePage('designPage')">Design</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="accountInfo" @click="changePage('editAccount')">Account</a>
        </li>
      </ul>
      <div v-if="this.currentPage == 'EditInfo'">
       <h3 class="title mt-3" style="text-align:center">Edit User</h3>
         <form class="center row g-3" @submit.prevent="updateAll">
              <h5>Basic Information </h5>
              <div class="col-md-6">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name"  placeholder="John Doe" v-model.trim="name" >
              </div>

              <div class="col-md-6">
                <label for="email" class="form-label">Email</label>
                <input type="text" class="form-control" id="email"  placeholder="johndoe@gmail.com" v-model.trim="email" >
              </div>

              <div class="col-md-6">
                <label for="companyName" class="form-label">Company Name</label>
                <input type="text" class="form-control" id="companyName"  placeholder="Tap Factor" v-model.trim="companyName" >
              </div>

              <div class="col-md-6">
                <label for="role" class="form-label">Role</label>
                <input type="text" class="form-control" id="role"  placeholder="Project Manager" v-model.trim="role" >
              </div>
            <!-- </div> -->
            
            <h5 class="mt-5">Contact Information </h5>
            
            <div class="col-md-4">
              <label for="contactNumber" class="form-label">Mobile Number</label>
              <input type="text" class="form-control" id="contactNumber"  placeholder="81234321" v-model.trim="contactNumber" >
            </div>

            <div class="col-md-4">
              <label for="officeNumber" class="form-label">Office Number</label>
              <input type="text" class="form-control" id="officeNumber"  placeholder="61231234" v-model.trim="officeNumber" >
            </div>

            <div class="col-md-4">
              <label for="faxNumber" class="form-label">Fax Number</label>
               <input type="text" class="form-control" id="faxNumber"  placeholder="64321234" v-model.trim="faxNumber" >  

            </div>

            <div class="col-6">
              <label for="tele" class="form-label">Telegram</label>
              <input type="text" class="form-control" id="tele"  placeholder="telegram username" v-model.trim="tele" >
            </div>

            <div class="col-6">
              <label for="whatsapp" class="form-label">Whatsapp No.</label>
              <input type="text" class="form-control" id="whatsapp"  placeholder="8123 4321" v-model.trim="whatsapp" >
             </div>
            
            <h5 class="mt-5">Social Media Information </h5>
            <div class="col-md-4">
              <label for="fb" class="form-label">Facebook</label>
              <input type="text" class="form-control" id="fb"  placeholder="facebook.com/username" v-model.trim="fb" >
            </div>

            <div class="col-md-4">
              <label for="twitter" class="form-label">Twitter</label>
              <input type="text" class="form-control" id="twitter"  placeholder="twitter.com/username" v-model.trim="twitter" >
            </div>
            
            <div class="col-md-4">
              <label for="insta" class="form-label">Instagram</label>
              <input type="text" class="form-control" id="insta"  placeholder="instagram.com/username" v-model.trim="insta" >
            </div>

            <div class="col-md-4">
              <label for="linkedin" class="form-label">Linkedin</label>
              <input type="text" class="form-control" id="linkedin"  placeholder="linkedin.com/username" v-model.trim="linkedin" >
            </div>
            
            <div class="col-md-4">
              <label for="website" class="form-label">Website</label>
              <input type="text" class="form-control" id="website"  placeholder="website.com" v-model.trim="website" >
            </div>

            <div class="col-md-4">
              <label for="youtube" class="form-label">Youtube</label>
              <input type="text" class="form-control" id="youtube"  placeholder="youtube.com/channel/<channelID>" v-model.trim="youtube" >
            </div>

            
            <div class="col-md-4">
              <label for="tiktok" class="form-label">Tiktok</label>
              <input type="text" class="form-control" id="tiktok"  placeholder="tiktok.com/@username" v-model.trim="tiktok" >
            </div>

            <div class="col-md-4">
              <label for="snapchat" class="form-label">Snapchat</label>
              <input type="text" class="form-control" id="snapchat"  placeholder="snapchat.com/add/<username>" v-model.trim="snapchat" >
            </div>


            <h5 class="mt-5">Additional Information </h5>

            <div class="col-12">
              <label for="address" class="form-label">Address</label>
              <input type="text" class="form-control" id="address"  placeholder="123 Orchard Road, Singapore 123321" v-model.trim="address" >
            </div>
            
            <div class="col-12">
              <label for="description" class="form-label">Description</label>
              <textarea type="text" class="form-control" id="description"  placeholder="Description ..." v-model.trim="description" />
            </div>
            
            <div class="col-12">
              <label for="formFile" class="form-label">Profile Image</label>
              <input 
                type="file" 
                id="profile"
                class="form-control profile"
                accept="image/png, image/gif, image/jpeg"
                ref="fileInput"
                @change="handleFileChange">
              <p v-if="this.fileSizeError!=''" class="text-danger">{{this.fileSizeError}}</p>

            </div>
            
            <div class="col-12 mt-4" v-if="this.updateStatus=='none'">
              <button type="submit" class="btn btn-primary">Update Info</button>
            </div>
            <div class="col-12 mt-4" v-if="this.updateStatus=='updating'">
              <button type="submit" class="btn btn-primary" disabled>
                <i class="fa fa-circle-o-notch fa-spin"></i>  Updating
              </button>
            </div>

          </form>  
        
    </div>
    <div v-if="this.currentPage == 'AccountInfo'">
       <h3 class="title mt-3" style="text-align:center">Account Info </h3>
        <form @submit.prevent="resetPassword" class ="center row g-3">
          <div class="col-12">
              <label for="formFile" class="form-label"><h5>Reset Password</h5></label>
          </div>
          <div class="col-12">
            <button type="submit" class="btn btn-primary">Reset Password</button>
          </div>
        </form>
      </div>

      <div v-if="this.currentPage == 'designPage'" class ="center">
       
        <h3 class="title mt-3" style="text-align:center">Design Options </h3>
        <div class="swiper-container"  ref="swiperContainer">
          <div class="swiper-wrapper">
            <div v-for="(value, key) in designExclusiveDic" :key="key" class="swiper-slide">
              <img class="card-img-left" style="width: 250px; height: 500px;" :src="value.video" />
                <button class="btn btn-primary btn-select" :disabled="designOption === key" @click="updateDesignTemplate2(key)" style="margin-top:40px;">Select</button>
            </div>
            <div v-for="(value, key) in designTemplateDic" :key="key" class="swiper-slide">
              <img class="card-img-left" style="width: 250px; height: 500px;" :src="value.video" />
                <button class="btn btn-primary btn-select" :disabled="designOption === key" @click="updateDesignTemplate2(key)" style="margin-top:40px;">Select</button>
            </div>
            
          </div>
          <br><br>
          <br><br>
          <div class="swiper-pagination" ></div>
          <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      
    </div>

  </div>
</template>
<script>
import axios from "axios";
import navbar from "../components/navbar.vue";
import { doc, onSnapshot,collection, query, orderBy, getDocs,getDoc,where } from "firebase/firestore";
import { db } from "../firebaseDB";
import router from "@/router";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import Swiper, { Navigation, Pagination,EffectCoverflow } from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
  components: { navbar },
  data() {
    return {
      name: "",
      email: "",
      companyName: "",
      contactNumber: "",
      role: "",
      fb: "",
      twitter: "",
      insta: "",
      linkedin: "",
      website: "",
      profileImg: "",
      address: "",
      companyID: "",
      accID: "",
      response: "",
      id_token: "",
      apiURL: "https://backend-tapfactor-updateuser-tapfactor-zacfaazhqa-uc.a.run.app",
      officeNumber:"",
      faxNumber:"",
      description:"",
      youtube:"",
      tiktok:"",
      whatsapp:"",
      tele:"",
      snapchat:"",
      docData: "",
      currentPage: "EditInfo",
      updateStatus: "none",
      designOption:"",
      designTemplateDic:{}, 
      designExclusiveDic:{},
      fileSizeLimitInMB:1,
      fileSizeError:"",
      selectedFile:null,
      swiper: null,
      currUserId: "",


    };
  },
  async mounted() {

    this.accID = this.$store.getters.getUserId;
    await this.checkAuthState();
    
    const docRef = await doc(db, "userProfile", this.accID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this.currUserId = this.accID
      await onSnapshot(docRef, (doc) => {
        var userData = doc.data();
        this.docData = userData;
        this.address = userData.address;
        this.contactNumber = userData.contactNumber;
        this.email = userData.email;
        this.fb = userData.fb;
        this.insta = userData.insta;
        this.linkedin = userData.linkedin;
        this.name = userData.name;
        this.profileImg = userData.profileImg;
        this.role = userData.role;
        this.twitter = userData.twitter;
        this.website = userData.website;
        this.companyName = userData.companyName;
        this.officeNumber = userData.officeNumber;
        this.faxNumber = userData.faxNumber;
        this.description = userData.description;
        this.youtube = userData.youtube;
        this.tiktok = userData.tiktok;
        this.whatsapp = userData.whatsapp;
        this.tele = userData.tele;
        this.snapchat = userData.snapchat;
        this.currPDFFile = userData.PdfFile;
        if (userData.imagePreview!=undefined){
             this.imagePreview = userData.imagePreview
            this.staticImagePreview =ref(JSON.parse(JSON.stringify(userData.imagePreview)));
        }else{
            this.docData.imagePreview = this.staticImagePreview
        }
        this.designOption= userData.designOption
        
        if (userData.designOption==undefined){
            this.updateDesignTemplate()
        } 
      
      });
    }else{
      console.log(this.accID)
      const q = query(collection(db, "userProfile"), where("userid", "==", this.accID ));
      await onSnapshot(q, async(doc1) => {
        if (!doc1.empty) {
          console.log(doc1.docs[0].data())
          this.currUserId = doc1.docs[0].id
          const userData = doc1.docs[0].data();
          this.docData = userData;
          this.address = userData.address;
          this.contactNumber = userData.contactNumber;
          this.email = userData.email;
          this.fb = userData.fb;
          this.insta = userData.insta;
          this.linkedin = userData.linkedin;
          this.name = userData.name;
          this.profileImg = userData.profileImg;
          this.role = userData.role;
          this.twitter = userData.twitter;
          this.website = userData.website;
          this.companyName = userData.companyName;
          this.officeNumber = userData.officeNumber;
          this.faxNumber = userData.faxNumber;
          this.description = userData.description;
          this.youtube = userData.youtube;
          this.tiktok = userData.tiktok;
          this.whatsapp = userData.whatsapp;
          this.tele = userData.tele;
          this.snapchat = userData.snapchat;
          this.currPDFFile = userData.PdfFile;

          if (userData.imagePreview!=undefined){
            this.imagePreview = userData.imagePreview
            this.staticImagePreview =ref(JSON.parse(JSON.stringify(userData.imagePreview)));
          }else{
              this.docData.imagePreview = this.staticImagePreview
          }
          this.designOption= userData.designOption
          
          if (userData.designOption==undefined){
              this.updateDesignTemplate()
          } 
          }else{
            router.push("/NotFound");
          }
        })
    }
    const colRef  = collection(db, "designTemplates");
  
    const deisgnQuery = query(colRef, orderBy("createdTime"));
    const designSnap = await getDocs(deisgnQuery)
    const designTemplateDic = {};
    const designExclusiveDic = {};
    designSnap.forEach(doc => { 
       if (doc.data().exclusive && doc.data().exclusiveList.includes(this.$store.getters.getUserId)) {
          designExclusiveDic[doc.id] = doc.data();
        } else if (!doc.data().exclusive) {
          designTemplateDic[doc.id] = doc.data();
        }
    });
    this.designTemplateDic = designTemplateDic;
    this.designExclusiveDic = designExclusiveDic;
    this.$nextTick(() => {
      this.initializeSwiper();
    });
  },
  watch: {
    currentPage(newValue) {
      if (newValue === 'designPage') {
        this.$nextTick(() => {
          this.initializeSwiper();
        });
      }
    },
  },
  methods: {
    initializeSwiper() {
      //Swiper
      Swiper.use([Navigation, Pagination, EffectCoverflow]);
      
      // this.$nextTick(() => {
        this.swiper=new Swiper('.swiper-container', {
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          initialSlide: 0,
          slideToClickedSlide: true,
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
          },
        });
  },
    handleFileChange(event) {
      const file = event.target.files[0];
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > this.fileSizeLimitInMB) {
        this.$refs.fileInput.value = '';
        this.selectedFile = null;
        this.fileSizeError="File Size Exceed Limit of 1MB"
      } else {
        this.selectedFile = file;
        this.fileSizeError=""
      }
    },
    async updateInfo(updateType) {
      var changeData;
      if (updateType == "name") {
        changeData = this.name;
      } else if (updateType == "email") {
        changeData = this.email;
      } else if (updateType == "address") {
        changeData = this.address;
      } else if (updateType == "contactNumber") {
        changeData = this.contactNumber;
      } else if (updateType == "role") {
        changeData = this.role;
      } else if (updateType == "fb") {
        changeData = this.fb;
      } else if (updateType == "twitter") {
        changeData = this.twitter;
      } else if (updateType == "insta") {
        changeData = this.insta;
      } else if (updateType == "linkedin") {
        changeData = this.linkedin;
      } else if (updateType == "website") {
        changeData = this.website;
      } else if (updateType == "companyName") {
        changeData = this.companyName;
      }else if (updateType == "officeNumber") {
        changeData = this.officeNumber;
      }else if (updateType == "faxNumber") {
        changeData = this.faxNumber;
      }
      else if (updateType == "description") {
        changeData = this.description;
      }
      else if (updateType == "youtube") {
        changeData = this.youtube;
      }
      else if (updateType == "snapchat") {
        changeData = this.snapchat;
      }
      else if (updateType == "tele") {
        changeData = this.tele;
      }
      else if (updateType == "whatsapp") {
        changeData = this.whatsapp;
      }
      else if (updateType == "tiktok") {
        changeData = this.tiktok;
      }
      axios
        .put(this.apiURL + "/updateInfo/", {
          accID: this.currUserId,
          change_type: updateType,
          change_data: changeData,
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return ;
          }
          // this.response = "success";
          // return "OK"
        })
        .catch(() => {
          // Handle error
          return "error"
        });
          return "OK"

      // setTimeout(() => {
      //   this.response = "";
      // }, 3000);
    },
    async updateImg() {
      // this.response = "";
      const profilePic = document.getElementById("profile").files[0];
      var profileImg = this.accID + "/profile/profileImg";
      await this.save_image(profileImg, profilePic);
      await axios
        .post(this.apiURL + "/updateImage/", {
          accID: this.currUserId,
          profileImg: this.profileImg,
          id_token: this.id_token,
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          // this.response = "success";
          document.getElementById('profile').value = ""
        })
        .catch(() => {
            return "error";
        });
      // setTimeout(() => {
      //   this.response = "";
      // }, 3000);
      return "OK"
    },
    async save_image(filename, image) {
      const storage = getStorage();
      const storageRef = ref(storage, filename);
      await uploadBytes(storageRef, image).then((snapshot) => {
        return getDownloadURL(snapshot.ref).then((downloadURL) => {
          this.profileImg = downloadURL;
        });
      });
    },
    async checkAuthState() {
      const auth = getAuth();
      await onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
           
            this.id_token = idToken;
          });
        } else {
          router.push("/");
        }
      });
    },
    resetPassword() {
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          alert("Email Sent! Please check you junk/spam folder");
        })
        .catch(() => {});
    },
    // async resetValue() {
    //   setTimeout(async function () {
        
    //     this.response = "";
    //   }, 2500);
    // },
    async updateNewImage(){
      if (document.getElementById("profile").files[0] != undefined){
        const result = await this.updateImg()
        return result
      }
      return "OK"
    },
    async updateNewInfo(){
      var result = []
      if (this.docData.address != this.address){
         const addRes = await this.updateInfo("address")
         result.push(addRes)
      }
      if (this.docData.companyName != this.companyName){
        const CNres = await this.updateInfo("companyName")
        result.push(CNres)

      }
      if (this.docData.contactNumber != this.contactNumber){
        const Numres = await this.updateInfo("contactNumber")
        result.push(Numres)
      }
      if (this.docData.description != this.description){
        const Dres = await this.updateInfo("description")
        result.push(Dres)
      }
      if (this.docData.email != this.email){
        const Emailres = await this.updateInfo("email")
        result.push(Emailres)
      }
      if (this.docData.faxNumber != this.faxNumber){
        const faxres = await this.updateInfo("faxNumber")
        result.push(faxres)
      }
      if (this.docData.fb != this.fb){
        const fbres = await this.updateInfo("fb")
        result.push(fbres)
      }
      if (this.docData.insta != this.insta){
        const instares = await this.updateInfo("insta")
        result.push(instares)
      }
      if (this.docData.linkedin != this.linkedin){
        const linkres = await this.updateInfo("linkedin")
        result.push(linkres)

      }
      if (this.docData.name != this.name){
        const nameres = await this.updateInfo("name")
        result.push(nameres)
      }
      if (this.docData.officeNumber != this.officeNumber){
        const ONres = await this.updateInfo("officeNumber")
        result.push(ONres)
        
      }
      if (this.docData.role != this.role){
        const roleRes = await this.updateInfo("role")
        result.push(roleRes)
      }
      if (this.docData.snapchat != this.snapchat){
        const snapRes = await this.updateInfo("snapchat")
        result.push(snapRes)
      }
      if (this.docData.tele != this.tele){
        const teleRest = await this.updateInfo("tele")
        result.push(teleRest)
        
      }
      if (this.docData.tiktok != this.tiktok){
        const tiktokRes = await this.updateInfo("tiktok")
        result.push(tiktokRes)
      }
      if (this.docData.twitter != this.twitter){
        const twitterRes = await this.updateInfo("twitter")
        result.push(twitterRes)
      }
      if (this.docData.website != this.website){
        const webRes = await this.updateInfo("website")
        result.push(webRes)
        
      }
      if (this.docData.whatsapp != this.whatsapp){
        const whatRes = await this.updateInfo("whatsapp")
        result.push(whatRes)
      }
      if (this.docData.youtube != this.youtube){
        const youRes = await this.updateInfo("youtube")
        result.push(youRes)
      }
      if (result.includes("error")){
        return "error"
      }
      return "OK";
    },

    async validateAndUpdate(){
      var allResult = []
      const infoRequest = await this.updateNewInfo();
      const imageRequestres =await this.updateNewImage();
      allResult.push(infoRequest)
      allResult.push(imageRequestres)
      if (allResult.includes("error")){
        return "error"
      }
      return "OK";
    },
    changePage(changeVal){
        if (changeVal=="editUser"){
          this.currentPage ="EditInfo"
          document.getElementById("editUser").className ="nav-link active"
          document.getElementById("designPage").className ="nav-link"
          document.getElementById("accountInfo").className ="nav-link"


        }
        else if(changeVal=="editAccount"){
          this.currentPage ="AccountInfo"
          document.getElementById("editUser").className ="nav-link"
          document.getElementById("designPage").className ="nav-link"
          document.getElementById("accountInfo").className ="nav-link active"
        }
        else if(changeVal=="designPage"){
          this.currentPage ="designPage"
          document.getElementById("editUser").className ="nav-link"
          document.getElementById("designPage").className ="nav-link active"
          document.getElementById("accountInfo").className ="nav-link"
        }
    },
    async updateAll(){
        this.updateStatus ="updating"
        var result = await this.validateAndUpdate();
        
        if (result =="OK"){
          this.response = "success";
          this.updateStatus ="none"

          setTimeout(() => {
            this.response = "";
          }, 3000);
        }
        else{
          this.response ="error"
          setTimeout(() => {
            this.response = "";
          }, 3000);

        }
      },
      updateDesignTemplate(){
        //basic template
        var updateType = "designOption"
        var changeData ="iLjAw41CX5E9YjPgA0KA"
        axios
          .put(this.apiURL + "/updateInfo/", {
            accID:  this.currUserId,
            change_type: updateType,
            change_data: changeData,
            id_token: this.id_token,
          })
          .then((response) => {
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            }
            
            // return "OK"
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
            

      },
      updateDesignTemplate2(designID){
        var updateType = "designOption"
        var changeData =designID
        axios
          .put(this.apiURL + "/updateInfo/", {
            accID:  this.currUserId,
            change_type: updateType,
            change_data: changeData,
            id_token: this.id_token,
          })
          .then((response) => {
            if (response.status == 401) {
              alert("Login Expire! Please Login Again");
              router.push("/");
              return ;
            }
            this.response = "success";
            setTimeout(() => {
              this.response = "";
            }, 3000);
          })
          .catch(() => {
            // Handle error
            // return "error"
          });
            // return "OK"
      }

  },
};
</script>

<style scoped>
body {
  background-color: white !important;
  font-family: "Montserrat",  sans-serif;
}
button{
  width: 200px;  
}
.alert {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  border-radius: 0px;
}
.center {
  margin: auto;
  width: 80%;
  padding: 10px;
}
a {
  text-decoration: none !important;
}
::placeholder { 
  opacity: 0.8; 
  }

.wholeCard{
  height: 400px;
}
@media (max-width:912px ) {
  .wholeCard{
    width:160%
  }
}

@media (max-width:280px) {
  .wholeCard{
    width:200%
  }
}


@media (max-width: 767px) {
  .col-4 {
    flex-basis: 100%;
  }

  .card-img-left {
    width: 100%;
    height: auto;
  }
  .card{
    margin-bottom: 20px;
  }
}
.swiper-slide {
width: 200px;
height: 500px;
/* background-color: #ccc; */
display: flex;
align-items: center;
justify-content: center;
/* font-size: 18px; */
}
.btn-select {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}
</style>