<template>
  <nav class="navbar navbar-dark justify-content-between navbar-main">
    <a
      class="navbar-brand"
      href=""
      @click="goHome()"
      style="margin-left: 80px"
    >
      <img src="../assets/TF_Logo_2.png" height="50" alt="" />
    </a>
    <div class="d-flex" style="margin-right: 80px">
      <div class="navmenu-div d-flex">
        <a class="nav-item nav-link" @click="logOut()" href="">Logout</a>
      </div>
    </div>
  </nav>
</template>
<script>
import router from "@/router";
import { getAuth, signOut } from "firebase/auth";

export default {
  // setup(){
  //     const route = useRoute();
  //     const id = route.params.id;
  // },
  date() {
    return {};
  },
  methods: {
    logOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          router.push("/");
        })
        .catch(() => {
          // An error happened.
        });
    },
  },
};
</script>

<style scoped>
.navbar-main {
  padding: 30px 0;
}

.navbar-brand {
  color: #000000 !important;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav-link {
  color: #898989;
}

.navbar {
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.navmenu-div a {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
}

.navmenu-div a:hover,
.active {
  color: #000000 !important;
}

.navmenu-div a:last-child {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.navmenu-div a:last-child:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>